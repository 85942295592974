import {useQuery} from '@apollo/client';

import {GetLocationByBusinessCodes, GetLocationByBusinessCodesVariables} from 'autogen/GetLocationByBusinessCodes';
import {graphqlClient} from 'client/graphql/graphql-client';
import IApolloQuery from 'client/types/apollo-query';
import {TWO_LEGGED_AUTH_TYPE} from 'client/enums/auth-types';

import {getLocationByBusinessCodes} from '../graphql/queries/location-queries';

import {useCustomerData} from './customer-hooks';

export interface ILocationHooks {
    locationIds: string[];
    locationIdLoading: boolean;
}

export const useLocationId = (storeCode: string | undefined, skip = false): ILocationHooks => {
    const businessCodes: string[] = [];

    if (storeCode && storeCode.length !== 0) {
        businessCodes.push(storeCode);
    }

    const {data, loading} = useQuery<GetLocationByBusinessCodes, IApolloQuery<GetLocationByBusinessCodesVariables>>(
        getLocationByBusinessCodes,
        {
            client: graphqlClient(),
            errorPolicy: 'all',
            skip: skip || businessCodes.length === 0,
            variables: {
                authType: TWO_LEGGED_AUTH_TYPE,
                businessCodes
            }
        }
    );

    if (loading || !data?.locationsByBusinessCodes?.length) {
        return {
            locationIdLoading: loading,
            locationIds: ['']
        };
    }

    return {
        locationIdLoading: loading,
        locationIds: data.locationsByBusinessCodes.map((location) => {
            return location.locationId;
        })
    };
};

export const useCustomerDataToGetLocationId = (): {locationId: string; loading: boolean} => {
    const {storeId} = useCustomerData();

    const {locationIds, locationIdLoading} = useLocationId(storeId.toString());

    return {
        loading: locationIdLoading,
        locationId: locationIds[0]
    };
};
