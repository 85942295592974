import {addHours, isAfter, parseISO} from 'date-fns';
import {OrderTypes} from '@hy-vee/shared-utils-aisles-online';
import {Dispatch, SetStateAction} from 'react';

import {graphqlClient} from 'client/graphql/graphql-client';
import {getIneligibleProductIdsFromBasketContinuityQuery} from 'client/graphql/queries/basket-continuity-queries';
import {ReservationViewType} from 'client/views/reservation-drawer';
import {BasketContinuityCartInput, FulfillmentType} from 'autogen/globalTypes';

import {updateBasketContinuity} from '../graphql/mutations/mutation-actions/basket-continuity-mutation-actions';
import {getUrl, assignParentUrl, postMessageToIframeParent} from '../utils/iframe-helpers';
import {deleteStoreSelectionCookies} from '../views/side-effects/fulfillment-change/change-location-clicked';
import {fetchAndResetClpeCartSavingsAction} from '../graphql/mutations/mutation-actions/clpe-mutation-actions';

interface IBasketContinutityInput {
    cartId: string | null;
    customerId?: number;
    deliveryAddressId?: number | null;
    fulfillmentLocationId?: number | null;
    fulfillmentType: string;
    isInactiveCartItemCheck?: boolean;
    storeId?: number | null;
    keepOpen?: boolean | null;
    pickupLocationId?: number | null;
    setActiveView?: Dispatch<SetStateAction<ReservationViewType>>;
    setBasketContinuityInput?: Dispatch<SetStateAction<BasketContinuityCartInput | any>>;
    closeDrawer?: () => void;
}

const getFulfillmentExpiration = (fulfillmentTime) => {
    const twoHoursFromNow = addHours(new Date(Date.now()), 2);

    if (fulfillmentTime) {
        return isAfter(twoHoursFromNow, parseISO(fulfillmentTime.cutoffTime))
            ? fulfillmentTime.cutoffTime
            : twoHoursFromNow.toISOString();
    }

    return null;
};

// This function is used for the old Reservation Stepper.
export const executeBasketContinuity = async (payload) => {
    const {
        cart,
        deliveryLocation,
        fulfillmentTime,
        fulfillmentType,
        newFulfillmentLocationId,
        pickupLocation,
        redirectOnChange,
        storeId
    } = payload;

    const basketContinuityData = await updateBasketContinuity(cart.cartId, cart.customerId, {
        deliveryAddressId: deliveryLocation ? Number(deliveryLocation.deliveryAddressId) : null,
        fulfillmentExpiration: getFulfillmentExpiration(fulfillmentTime),
        fulfillmentId: fulfillmentTime?.fulfillmentScheduleId,
        fulfillmentLocationId: Number(newFulfillmentLocationId),
        fulfillmentType,
        fulfillmentWindowEnd: fulfillmentTime?.windowEnd,
        fulfillmentWindowStart: fulfillmentTime?.windowStart,
        pickupLocationId:
            pickupLocation?.pickupLocationId && fulfillmentType === OrderTypes.PICKUP_POINT
                ? Number(pickupLocation.pickupLocationId)
                : null,
        storeId: Number(storeId)
    });

    deleteStoreSelectionCookies();
    postMessageToIframeParent('reservation-stepper-review-success');

    const fulfillmentTypeChange = cart.fulfillmentType !== fulfillmentType;
    const locationChange = cart.storeId !== parseInt(storeId);
    const cartId = basketContinuityData.data.executeBasketContinuity.cartId;

    await fetchAndResetClpeCartSavingsAction(cartId);

    if (redirectOnChange || fulfillmentTypeChange || locationChange) {
        assignParentUrl(getUrl());
    }
};

// function for Executing Update on Basket Continuity on new Reservation Stepper
export const executeBasketContinuityDrawer = async (payload: Omit<IBasketContinutityInput, 'setActiveView'>) => {
    const {
        cartId,
        customerId = null,
        deliveryAddressId,
        fulfillmentLocationId,
        fulfillmentType,
        pickupLocationId,
        keepOpen,
        storeId,
        closeDrawer
    } = payload;

    const {data} = await updateBasketContinuity(Number(cartId), customerId, {
        deliveryAddressId,
        fulfillmentLocationId,
        fulfillmentType,
        pickupLocationId:
            pickupLocationId && fulfillmentType === FulfillmentType.PICKUP_POINT ? pickupLocationId : null,
        storeId
    });

    if (data?.executeBasketContinuity?.cartId && !keepOpen && closeDrawer) {
        closeDrawer();
    }
};

// function for getting Basket Summary on new Reservation Stepper
export const getBasketContinuitySummaryDrawer = async (payload: IBasketContinutityInput) => {
    const {
        cartId,
        deliveryAddressId,
        fulfillmentLocationId,
        fulfillmentType,
        isInactiveCartItemCheck,
        pickupLocationId,
        storeId,
        setActiveView,
        setBasketContinuityInput
    } = payload;

    const input = {
        deliveryAddressId,
        fulfillmentLocationId,
        fulfillmentType,
        pickupLocationId:
            pickupLocationId && fulfillmentType === FulfillmentType.PICKUP_POINT ? pickupLocationId : null,
        storeId
    };

    const {data} = await graphqlClient().query({
        fetchPolicy: 'no-cache',
        query: getIneligibleProductIdsFromBasketContinuityQuery,
        variables: {
            cartId: cartId || '',
            input
        }
    });

    if (data?.getBasketContinuitySummary?.ineligibleProductIds?.length && isInactiveCartItemCheck) {
        await executeBasketContinuityDrawer(payload);
    } else if (data?.getBasketContinuitySummary?.ineligibleProductIds?.length && setActiveView) {
        if (setBasketContinuityInput)
            setBasketContinuityInput({
                deliveryAddressId,
                fulfillmentLocationId,
                fulfillmentType,
                isInactiveCartItemCheck,
                pickupLocationId,
                storeId
            });
        setActiveView(ReservationViewType.BASKET_CONTINUITY);
    } else if (data?.getBasketContinuitySummary?.ineligibleProductIds?.length === 0 && !isInactiveCartItemCheck) {
        await executeBasketContinuityDrawer(payload);
    }
};
